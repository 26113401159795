import React, { Component } from 'react';
import Img from 'gatsby-image';
import _ from 'lodash';
import Masonry from 'react-masonry-component';
import { Link, graphql } from 'gatsby';

import styled from 'styled-components';
import { convertSlugToPath } from '../utils/helpers';
import Content from '../components/Content';
import Layout from '../components/Layout';

const PostImg = styled(Img)`
  &:hover {
    transform: translate3d(0, -2px, 0);
    opacity: 0.9;
  }
`;
const PostTitle = styled(Link)`
  h4 {
    margin-bottom: 0;
    &:hover {
      text-decoration: underline;
    }
  }
`;

class Projects extends Component {
  state = {
    filter: '',
    allPosts: [],
    filteredPosts: [],
    tags: [],
  };
  constructor(props) {
    super(props);
    this.state.allPosts = props.data.posts.edges;
    this.state.filteredPosts = props.data.posts.edges;
    this.state.tags = _.without(
      _.uniq(
        _.flatMap(props.data.posts.edges, ({ node }) => {
          return node.frontmatter.tags;
        })
      ),
      'project'
    );
  }
  handleChange = e => {
    let filteredPosts = _.filter(this.state.allPosts, ({ node }) => {
      return _.includes(node.frontmatter.tags, e.target.value);
    });
    if (e.target.value === '') {
      filteredPosts = this.state.allPosts;
    }
    this.setState({ filteredPosts, filter: e.target.value });
  };
  renderPosts = () => {
    const { filteredPosts } = this.state;
    return filteredPosts.map(({ node }) => {
      return (
        <div className="col-md-4" key={node.id}>
          <article className="boxed boxed--border">
            {node.frontmatter.image && (
              <Link to={convertSlugToPath(node.fields.slug)}>
                <PostImg
                  className="border--round mb--1"
                  fluid={node.frontmatter.image.childImageSharp.fluid}
                />
              </Link>
            )}
            <PostTitle to={convertSlugToPath(node.fields.slug)}>
              <h4>{node.frontmatter.title}</h4>
            </PostTitle>
            <span className="type--fine-print type--fade">
              {node.frontmatter.subtitle}
            </span>
            <Content content={node.frontmatter.description} />
          </article>
        </div>
      );
    });
  };
  render() {
    const { tags } = this.state;
    const {
      data: { page },
    } = this.props;
    return (
      <Layout page={page}>
        <section className="space--sm">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <h1>{page.frontmatter.title}</h1>
                <p className="lead">{page.frontmatter.description}</p>
              </div>
            </div>
            <div className="row justify-content-center mt--1 mb--1">
              <div className="col-md-5">
                <div className="input-select">
                  <select
                    value={this.state.filter}
                    onChange={this.handleChange}
                  >
                    <option value="">All</option>
                    {tags.map(tag => (
                      <option key={tag} value={tag}>
                        {_.startCase(tag)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Masonry
                  className={'row'} // default ''
                  elementType={'div'} // default 'div'
                  options={{
                    transitionDuration: 500,
                  }} // default {}
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                  imagesLoadedOptions={{}} // default {}
                >
                  {this.renderPosts()}
                </Masonry>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Projects;

export const query = graphql`
  query GetBlogProjects($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        metaTitle
        metaDescription
        description
      }
    }
    posts: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "blog" }
          tags: { in: "project" }
          status: { eq: "active" }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            description
            tags
            date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 240, quality: 75) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
